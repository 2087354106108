.App {
  text-align: center;
  height: 100%;
}

/*
:root {
  --fc-button-text-color: green;
  --fc-button-bg-color: red;
  --fc-button-border-color: blue;
  --fc-button-hover-bg-color: yellow;
  --fc-button-hover-border-color: pink;
  --fc-button-active-bg-color: orange;
  --fc-button-active-border-color: teal;
}
*/

#root .fc .fc-toolbar.fc-header-toolbar {
  margin-bottom: 0;
  padding: 0.5rem;
}

#root .fc-button {
  font-size: 0.9rem;
}

#root h2 {
  font-size: 1.2rem;
}
